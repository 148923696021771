import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  BodyTitle,
  BodyFilter,
  TableNoData,
  Textwrap,
} from "../components/body";
import { Ajax } from "../js/ajax";
import { STATUSLIST, SUBJECTLIST } from "../js/lib";
import DropdownList from "../components/dropdownlist";
import Button from "../components/button.js";
import { alertFailLogin } from "../components/alert";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/datepicker.css";
import moment from "moment";
import NavbarSides from "../components/navsidesbar";
import Invoice from "./invioce";
const Purchaserecordlisting = () => {
  //call when on click

  const componentsRef = useRef();

  const [tempvalue, settempvalue] = React.useState(0);
  const [isOnclick, setIsOnclick] = React.useState(false);
  const [smsLog, setSmsLog] = React.useState([]);
  const date = new Date();

  const [startDate, setStartDate] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );

  const [status, setSatus] = React.useState("");
  const [endDate, setEndDate] = React.useState(new Date());
  const [record, setRecord] = React.useState("");
  const [subject, setSubject] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [maxPage, setMaxPage] = React.useState(1);
  const [recordNum, setRecordNum] = React.useState(1);

  const [pagination, setPagination] = React.useState([]);

  const changePage = (label) => {
    if (label === "Previous" && currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    } else if (label === "Next" && currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    } else if (label >= 1) {
      setCurrentPage(label);
    }
  };
  const handle = useReactToPrint({
    content: () => componentsRef.current,
    documentTitle: "inv",
  });
  const statusHandler = (e) => setSatus(e.target.value);
  const subjectHandler = (e) => setSubject(e.target.value);
  const listinghandle = () => {
    const getdateFrom = moment(startDate).format("YYYY-MM-DD");

    const getdateto = moment(endDate).format("YYYY-MM-DD");

    setIsOnclick(true);
    const data = {
      status: status,
      startDate: getdateFrom,
      endDate: getdateto,
      subject: subject,
      page: currentPage,
    };

    Ajax({
      url: `getTopUpHistroy`,
      data: data,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        setRecord(result.data.total);

        setCurrentPage(result.data.current_page);
        setMaxPage(result.data.last_page);
        setRecordNum(result.data.from);
        if (result.data.data.length > 0) {
          setSmsLog([result.data.data]);
        } else {
          setSmsLog([]);
        }

        setPagination(result.data.links);
      },
      fail: (fail) => {
        alertFailLogin(fail, null, "error");
      },
    });
  };

  React.useEffect(() => {
    listinghandle();
    return () => {};
  }, [tempvalue, currentPage]);

  return (
    <>
      <NavbarSides>
        <div className="container">
          <BodyTitle className={""} title={"Top Up History"}></BodyTitle>
          <div className="d-flex justify-content-center col-md-12">
            <BodyFilter>
              <div className="col">
                <div className="col">
                  <div className="row">
                    <div className="col-4 col-sm-6">
                      <label htmlFor="DateFrom" className="fw-bold">
                        Date From:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6">
                      <DatePicker
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="col">
                  <div className="row">
                    <div className="col-4 col-sm-6">
                      <label htmlFor="DateTo" className="fw-bold">
                        Date To:
                      </label>
                    </div>
                    <div className="col-12 col-sm-6">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        maxDate={new Date()}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="p-3 ">
                  <DropdownList
                    label={"Status"}
                    value={status}
                    items={STATUSLIST}
                    valueChanged={statusHandler}
                  />
                </div>
              </div>
              <div className="col">
                <div className="p-3">
                  <Button
                    name="submit"
                    className=" text-white mx-1 d-grid gap-2"
                    isDisabled={false}
                    onTap={listinghandle}
                    buttonType="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </BodyFilter>
          </div>

          <div className="row ">
            <div className="  d-flex justify-content-start col-md-12">
              <div className="container table-responsive-sm">
                <table className="table">
                  <caption>
                    Total Record : {record}
                    {smsLog.length != 0 && !isOnclick ? (
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center mt-2">
                          {pagination.map((page, index) => {
                            return (
                              <li
                                className={
                                  page.url == null
                                    ? "page-item disabled"
                                    : page.active
                                    ? "page-item active"
                                    : "page-item "
                                }
                                key={index}
                                onClick={changePage.bind(this, page.label)}
                              >
                                <a className="page-link" aria-disabled="true">
                                  {page.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                    ) : null}
                  </caption>

                  <thead className=" table-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Invoice No</th>
                      <th scope="col">Top Up Amount</th>
                      <th scope="col">status</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {smsLog.length === 0 ? (
                      <TableNoData />
                    ) : (
                      smsLog.map((log, index) =>
                        log.map((innerdata, innerindex) => {
                          return (
                            <tr key={`${index}_${innerindex}`}>
                              <th scope="row">{recordNum + innerindex}</th>
                              <td>
                                {moment(innerdata.created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              <td>{innerdata.invoice_no}</td>
                              <td>{innerdata.amount}</td>
                              <td>
                                <Textwrap module={innerdata.status}>
                                  {innerdata.status}
                                </Textwrap>
                              </td>
                              <td>{innerdata.remark}</td>
                              <td>
                                {innerdata.status == "Completed" ? (
                                  <>
                                    <a
                                      className="btn btn-primary"
                                      role="button"
                                      onClick={handle}
                                    >
                                      Invoice
                                    </a>
                                    <div className="d-none">
                                      <div ref={componentsRef}>
                                        <Invoice
                                          name={"Top Up"}
                                          invoice_id={innerdata.invoice_no}
                                          invoice_date={moment(
                                            innerdata.created_at
                                          ).format("YYYY-MM-DD")}
                                          price={innerdata.amount}
                                          customer_name={innerdata.firstname}
                                          customer_phone={innerdata.phone}
                                          customer_email={innerdata.email}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </NavbarSides>
    </>
  );
};

export default Purchaserecordlisting;
