import React from "react";
import TextInput from "../components/textInput.js";
import LongButton from "../components/longbutton.js";
import NavbarTop from "../components/topnavbar";
import { alertFailLogin } from "../components/alert";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import { validateRequired, validateEmail } from "../js/validation";
import { Ajax } from "../js/ajax";
import { setToken, setUser } from "../store/reducer";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // delcare varibale
  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState("");
  // password
  const [password, setPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState("");
  // set value for email input
  const emailHandler = (e) => setEmail(e.target.value);
  const passwordHandler = (e) => setPassword(e.target.value);
  // use for validation
  const [isErrorEmail, setIsErrorEmail] = React.useState("");
  const [isErrorPassowrd, setIsErrorPassowrd] = React.useState("");
  const [tempvalue, settempvalue] = React.useState(0);
  const [isOnclick, setIsOnclick] = React.useState(false);

  //call when on click
  const loginHandler = () => {
    setIsOnclick(true);
    const data = {
      email: email,
      password: password,
    };

    Ajax({
      url: `login`,
      data: data,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        dispatch(setToken(result.token));
        localStorage.setItem("accessToken", result.token);
        localStorage.setItem("UserFirstName", result.data.firstname);
        navigate("/dashboard");
        // window.location.reload();
      },
      fail: (fail) => {
        alertFailLogin(fail, null, "error");
        dispatch(setToken(""));
      },
    });
  };
  const loginwithtokenHandler = (accesstoken, username) => {
    localStorage.setItem("accessToken", accesstoken);
    localStorage.setItem("UserFirstName", username);
    navigate("/purchaseProduct");
  };
  const validation = () => {
    if (validateRequired(email) === false) {
      setIsErrorEmail(true);
      setErrorEmail("Email Should Not be Empty");
    } else if (validateEmail(email) === false) {
      setIsErrorEmail(true);
      setErrorEmail("Invalid Format for Email");
    } else {
      setIsErrorEmail(false);
    }
    if (validateRequired(password) === false) {
      setIsErrorPassowrd(true);
      setErrorPassword("Password Should Not be Empty");
    } else {
      setIsErrorPassowrd(false);
    }
    settempvalue(tempvalue + 1);
  };

  React.useEffect(() => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const token = urlParams.get("token");
    const username = urlParams.get("username");

    if (token && username) loginwithtokenHandler(token, username);
    if (isErrorEmail === false && isErrorPassowrd === false) {
      loginHandler();
    }
  }, [tempvalue]);

  return (
    <>
      <NavbarTop />
      <div className="d-flex justify-content-center">
        <div className="card col-lg-4 col-12  col-sm-8">
          <div className="card-body">
            <h5 className="card-title">Login</h5>
            <hr></hr>
            <div className="card-body">
              <div className="col-12">
                <TextInput
                  classNameName={""}
                  errorMessage={errorEmail}
                  hints={[]}
                  inputType={"text"}
                  isEdit={true}
                  isError={isErrorEmail}
                  isRequired={true}
                  label={"Email"}
                  maxLength={250}
                  name={"email"}
                  onChange={emailHandler}
                  placeholder="Email"
                  value={email}
                />
              </div>
              <div className="col-12">
                <TextInput
                  classNameName={""}
                  errorMessage={errorPassword}
                  hints={[]}
                  inputType={"password"}
                  isEdit={true}
                  isError={isErrorPassowrd}
                  isRequired={true}
                  label={"Password"}
                  maxLength={250}
                  name={"password"}
                  onChange={passwordHandler}
                  placeholder="Email"
                  value={password}
                />
              </div>
              {isOnclick ? (
                <>
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <LongButton
                    onTap={validation}
                    name="submit"
                    className="rounded-pill"
                  >
                    submit
                  </LongButton>
                </div>
              )}
              <div className="d-flex justify-content-center mt-3">
                <Link to="/register">
                  <p className={"px-2"} htmlFor={"signUp"}>
                    Don't have an account? Sign Up
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
