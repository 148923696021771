import React from "react";
import TextInput from "../components/textInput.js";
import LongButton from "../components/longbutton.js";

import { alertRegisterSuccess } from "../components/alert";
import NavbarTop from "../components/topnavbar";
import {
  validateRequired,
  validateEmail,
  validatePhonelength,
} from "../js/validation";
import { Ajax } from "../js/ajax";
const Register = () => {
  // delcare varibale
  const [phone, setPhone] = React.useState("");
  const [ErrorPhone, setErrorPhone] = React.useState("");
  //
  const [email, setEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState("");
  // password
  const [password, setPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState("");
  const [reenterPassword, setReenterPassword] = React.useState("");
  const [errorReenterPassword, seterrorReenterPassword] = React.useState("");
  // name
  const [firstName, setFirstName] = React.useState("");
  const [errorFirstName, seterrorFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [errorLastName, seterrorLastName] = React.useState("");
  // set value for email input
  const phoneHandler = (e) => setPhone(e.target.value);
  const emailHandler = (e) => setEmail(e.target.value);
  const passwordHandler = (e) => setPassword(e.target.value);
  const firstNameHandler = (e) => setFirstName(e.target.value);
  const lastNameHandler = (e) => setLastName(e.target.value);
  const reenterPasswordHandler = (e) => setReenterPassword(e.target.value);
  // use for validation
  const [isErrorEmail, setIsErrorEmail] = React.useState("");
  const [isErrorPassowrd, setIsErrorPassowrd] = React.useState("");
  const [isErrorLastName, setIsErrorLastName] = React.useState("");
  const [isErrorFirstName, setIsErrorFirstName] = React.useState("");
  const [isErrorPhone, setIsErrorPhone] = React.useState("");
  const [isErrorReenterPassword, setIsErrorReenterPassword] =
    React.useState("");
  const [tempvalue, settempvalue] = React.useState(0);
  const [isOnclick, setIsOnclick] = React.useState(false);
  const registerHandler = () => {
    setIsOnclick(true);
    const data = {
      email: email,
      password: password,
      lastname: lastName,
      firstname: firstName,
      phone: phone,
    };

    Ajax({
      url: `register`,
      data: data,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        alertRegisterSuccess(result, null, "success");
      },
      fail: (fail) => {
        if (fail.status === "320") {
          if (fail.errors.email) {
            setErrorEmail(fail.errors.email);
            setIsErrorEmail(true);
          } else {
            setIsErrorEmail("");
          }
          if (fail.errors.password) {
            setErrorPassword(fail.errors.password);
            setIsErrorPassowrd(true);
          } else {
            setIsErrorPassowrd("");
          }
          if (fail.errors.lastname) {
            seterrorLastName(fail.errors.lastname);
            setIsErrorLastName(true);
          } else {
            setIsErrorLastName("");
          }
          if (fail.errors.firstname) {
            seterrorFirstName(fail.errors.firstname);
            setIsErrorFirstName(true);
          } else {
            setIsErrorFirstName("");
          }
          if (fail.errors.phone) {
            setErrorPhone(fail.errors.phone);
            setIsErrorPhone(true);
          } else {
            setIsErrorPhone("");
          }
        }
      },
    });
  };
  //call when on click
  const validation = () => {
    if (validateRequired(phone) === false) {
      setErrorPhone("Phone Should Not be Empty");
      setIsErrorPhone(true);
    } else if (validatePhonelength(phone) === false) {
      setErrorPhone("Phone Must In between 10 to 12 digit");
      setIsErrorPhone(true);
    } else {
      setIsErrorPhone(false);
    }
    if (validateRequired(email) === false) {
      setIsErrorEmail(true);
      setErrorEmail("Email Should Not be Empty");
    } else if (validateEmail(email) === false) {
      setIsErrorEmail(true);
      setErrorEmail("Invalid Format for Email");
    } else {
      setIsErrorEmail(false);
    }
    if (validateRequired(password) === false) {
      setIsErrorPassowrd(true);
      setErrorPassword("Password Should Not be Empty");
    } else {
      setIsErrorPassowrd(false);
    }
    // last name validation
    if (validateRequired(lastName) === false) {
      setIsErrorLastName(true);
      seterrorLastName("Last Name Should Not be Empty");
    } else {
      setIsErrorLastName(false);
    }
    // first name validation
    if (validateRequired(firstName) === false) {
      setIsErrorFirstName(true);
      seterrorFirstName("First Name Should Not be Empty");
    } else {
      setIsErrorFirstName(false);
    }
    //reentry password
    if (validateRequired(reenterPassword) === false) {
      setIsErrorReenterPassword(true);
      seterrorReenterPassword("Reenter Password Should Not be Empty");
    } else {
      setIsErrorReenterPassword(false);
      if (reenterPassword !== password) {
        setIsErrorReenterPassword(true);
        seterrorReenterPassword("Reenter Password and Password Should be Same");
      } else {
        setIsErrorReenterPassword(false);
      }

      settempvalue(tempvalue + 1);
    }
  };
  React.useEffect(() => {
    if (
      isErrorReenterPassword === false &&
      isErrorFirstName === false &&
      isErrorLastName === false &&
      isErrorPassowrd === false &&
      isErrorEmail === false
    ) {
      registerHandler();
    }
  }, [tempvalue]);
  return (
    <>
      <NavbarTop />
      <div className="d-flex justify-content-center mt-2">
        <div className="card col-lg-4 col-12  col-sm-8">
          <div className="card-body">
            <h5 className="card-title">Register</h5>
            <hr></hr>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorFirstName}
                    hints={[]}
                    inputType={"text"}
                    isEdit={true}
                    isError={isErrorFirstName}
                    isRequired={true}
                    label={"First Name"}
                    maxLength={250}
                    name={"firstName"}
                    onChange={firstNameHandler}
                    placeholder="First Name"
                    value={firstName}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorLastName}
                    hints={[]}
                    inputType={"text"}
                    isEdit={true}
                    isError={isErrorLastName}
                    isRequired={true}
                    label={"Last Name"}
                    maxLength={250}
                    name={"lastName"}
                    onChange={lastNameHandler}
                    placeholder="Last Name"
                    value={lastName}
                  />
                </div>
              </div>
              <div className="col-12">
                <TextInput
                  classNameName={""}
                  errorMessage={errorEmail}
                  hints={[]}
                  inputType={"text"}
                  isEdit={true}
                  isError={isErrorEmail}
                  isRequired={true}
                  label={"Email"}
                  maxLength={250}
                  name={"email"}
                  onChange={emailHandler}
                  placeholder="Email"
                  value={email}
                />
              </div>
              <div className="col-12">
                <TextInput
                  classNameName={""}
                  errorMessage={ErrorPhone}
                  hints={[]}
                  inputType={"text"}
                  isEdit={true}
                  isError={isErrorPhone}
                  isRequired={true}
                  label={"Phone"}
                  maxLength={250}
                  name={"Phone"}
                  onChange={phoneHandler}
                  placeholder="Phone"
                  value={phone}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorPassword}
                    hints={[]}
                    inputType={"password"}
                    isEdit={true}
                    isError={isErrorPassowrd}
                    isRequired={true}
                    label={"Password"}
                    maxLength={250}
                    name={"password"}
                    onChange={passwordHandler}
                    placeholder="Email"
                    value={password}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorReenterPassword}
                    hints={[]}
                    inputType={"password"}
                    isEdit={true}
                    isError={isErrorReenterPassword}
                    isRequired={true}
                    label={"Reenter Password"}
                    maxLength={250}
                    name={"reenterPassword"}
                    onChange={reenterPasswordHandler}
                    placeholder="Reenter Password"
                    value={reenterPassword}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {isOnclick ? (
                  <>
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <LongButton
                    onTap={validation}
                    name="submit"
                    className="rounded-pill"
                  >
                    Register
                  </LongButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
