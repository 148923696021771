import React from "react";
const Button = (props) => {
  const { buttonType, className, isDisabled = false, name, onTap } = props;
  const getBgColor = () => {
    // if (isDisabled) return "cursor-not-allowed bg-gray-400";

    switch (buttonType) {
      case "close":
        return "btn btn-danger";
      case "cancel":
        return "btn btn-warning";
      case "submit":
        return "btn btn-primary";
      case "info":
        return "btn btn-info";
      case "success":
        return "btn btn-success";
      default:
        return "btn";
    }
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={` ${className}`}>
      <button
        className={`${getBgColor()} ${className}`}
        name={name}
        onClick={isDisabled ? undefined : onTap}
      >
        {props.children}
      </button>
    </div>
  );
};

export default Button;
