import React from "react";
import "../assets/HomePage/homepage.css";
import HomePagePic from "../assets/HomePage/banner-isometric.png";
import Api from "../assets/HomePage/Api.png";
import NavbarTop from "../components/topnavbar";
const HomePage = () => {
  return (
    <>
      <div id="gradientslightright">
        <NavbarTop />
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-row-reverse align-items-center g-5 py-5">
            <div className="col-12 col-lg-6">
              <img
                src={HomePagePic}
                className="d-block mx-lg-auto img-fluid"
                alt="Home Page"
                width="700"
                height="500"
              />
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">
                BlueStacks Android Emulator RDP
              </h1>
              <p className="lead">
                Bluestacks RDP/VPS plan with Admin Access & with customization
                option, unlimited access and with speed Upto 1 GBPS.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="gradientslight">
        <div className="container px-4 py-5 ">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            Why Choose Our Android Emulator Service
          </h1>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-pc-display-horizontal"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">Dedicated Resources to VPS</h4>
                <p>
                  All resources belong to you, including CPU, RAM, and disk
                  space. You don’t have to worry about other users’ website
                  traffic affecting your site’s performance.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-arrows-fullscreen"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">Easy to Expand</h4>
                <p>
                  If you need to upgrade your server environment because you
                  require more resources, you can send us a ticket. There is no
                  need to transfer files.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i className="bi bi-sliders" style={{ fontSize: "4em" }} />
                <h4 className="fw-bold mb-0">Full Control and Flexibility</h4>
                <p>
                  Enjoy the flexibility of virtual server hosting and complete
                  control over your operating system. Full admin/root access
                  lets you configure your server environment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 py-5 ">
          <h1 className="display-5 fw-bold lh-1 mb-3">Our Plan</h1>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-pc-display-horizontal"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">Regular RDP</h4>
                <p>
                  All resources belong to you, including CPU, RAM, and disk
                  space. You don’t have to worry about other users’ website
                  traffic affecting your site’s performance.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-arrows-fullscreen"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">Pro RDP</h4>
                <p>
                  If you need to upgrade your server environment because you
                  require more resources, you can send us a ticket. There is no
                  need to transfer files.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i className="bi bi-sliders" style={{ fontSize: "4em" }} />
                <h4 className="fw-bold mb-0">Optimized RDP</h4>
                <p>
                  Enjoy the flexibility of virtual server hosting and complete
                  control over your operating system. Full admin/root access
                  lets you configure your server environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer  py-5 bg-dark">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 ">
            <div className="col d-flex align-items-start">
              <div className="col-12">
                <h5 className=" fw-bold text-muted">EvaLink</h5>
                <span className=" fw-bold text-muted">
                  V03-08-02 DESIGNER OFFICE, SUNWAY VELOCITY, 55200 KUALA LUMPUR
                </span>
              </div>
            </div>
            <div className="col d-flex align-items-start"></div>
            <div className="col d-flex align-items-start">
              <div>
                <h6 className="fw-bold text-muted">Contact Us:</h6>
                <h6 className="fw-bold text-muted">Phone: +603-9779 0801</h6>
                <h6 className="fw-bold text-muted">
                  Email:support@evalink.com
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
