import React from "react";
import { BodyTitle } from "../components/body";
import { Ajax } from "../js/ajax";
import { alertFailLogin } from "../components/alert";
import LongButton from "../components/longbutton.js";
import Button from "../components/button.js";
import FormtextInput from "../components/formsinput.js";
import { validateRequired } from "../js/validation";
import { useNavigate } from "react-router-dom";

import NavbarSides from "../components/navsidesbar";
const PurchaseProduct = () => {
  //call when on click
  const [remark, setremark] = React.useState("");
  const navigate = useNavigate();
  // password

  const [price, setPrice] = React.useState(0);
  const [productId, setProductId] = React.useState("");
  const [errorBalance, setErrorBalance] = React.useState("");
  // set value for email validation

  const remarkHandler = (e) => setremark(e.target.value);
  // use for validation

  const [isErrorBalance, setIsErrorBalance] = React.useState("");
  const [tempvalue, settempvalue] = React.useState(0);
  const [isOnclick, setIsOnclick] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const getprouductlist = () => {
    setProductList([]);
    Ajax({
      url: `productList`,
      complete: () => {},
      success: (result) => {
        setProductList([result.data.Topup]);
        setProductId(result.data.Topup.product_id);
      },
      fail: (fail) => {},
    });
  };
  const amountHandler = (price) => {
    setPrice(price);
  };
  const purchaseHandler = () => {
    setIsOnclick(true);
    const data = {
      balance: price,

      product_id: productId,
      remark: remark,
    };
    Ajax({
      url: `createbill`,
      data: data,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        window.location = result.data;
      },
      fail: (fail) => {
        alertFailLogin(fail, null, "error");
      },
    });
  };

  const validation = () => {
    // if (validateRequired(password) === false) {
    //   setIsErrorPassowrd(true);
    //   setErrorPassword("Password Should Not be Empty");
    // } else {
    //   setIsErrorPassowrd(false);
    // }
    if (price <= 0) {
      setIsErrorBalance(true);
      setErrorBalance("Top up Amount can not be 0");
    } else {
      setIsErrorBalance(false);
    }
    settempvalue(tempvalue + 1);
  };

  React.useEffect(() => {
    getprouductlist();
    return () => {};
  }, []);

  React.useEffect(() => {
    if (isErrorBalance === false) {
      purchaseHandler();
    }
    return () => {};
  }, [tempvalue]);

  return (
    <>
      <NavbarSides>
        <BodyTitle className={""} title={"Top Up"}></BodyTitle>
        <div className="container">
          <div className="row">
            <div className="  d-flex justify-content-start col-md-7">
              <div className="container">
                <div className="card">
                  <div className="card-body">
                    <div className="row my-3">
                      <div className="col-12 col-lg-3 d-flex justify-content-start">
                        <h6 className="text-muted">Top Up Price (RM):</h6>
                      </div>
                      <div className="col-12 col-lg-8">
                        <div className="row row-cols-2 row-cols-md-5 g-3  d-flex align-items-center ">
                          {productList.map((product, index) =>
                            product.price.map((innerdata, innerindex) => {
                              return (
                                <div className="col" key={innerdata}>
                                  {innerdata === price ? (
                                    <Button
                                      className="btn-outline-primary rounded-pill active"
                                      onTap={amountHandler.bind(
                                        this,
                                        innerdata
                                      )}
                                      key={innerdata}
                                    >
                                      {innerdata}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn-outline-primary rounded-pill"
                                      onTap={amountHandler.bind(
                                        this,
                                        innerdata
                                      )}
                                      key={innerdata}
                                    >
                                      {innerdata}
                                    </Button>
                                  )}
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                    {isErrorBalance ? (
                      <>
                        <p className={"px-2 text-danger"} htmlFor={"name"}>
                          <i className="bi bi-exclamation-circle me-2"></i>
                          {errorBalance}
                        </p>
                      </>
                    ) : null}

                    <div className="col-auto">
                      <FormtextInput
                        className={"d-flex justify-content-start"}
                        hints={[]}
                        inputType={"text"}
                        isEdit={true}
                        isRequired={false}
                        label={"Remark"}
                        maxLength={250}
                        name={"remark"}
                        onChange={remarkHandler}
                        placeholder="Remark(Optional)"
                        value={remark}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="card-header bg-dark text-white">
                  <h5 className="card-title">Top Up Summary</h5>
                </div>
                <div className="card-body">
                  <div className="row my-3">
                    <div className="col-4">
                      <h6 className="card-subtitle mb-2 text-muted">
                        Top Up Price (RM):
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="card-subtitle mb-2 text-muted">
                        {price}.00
                      </h6>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-4">
                      <h6 className="card-subtitle mb-2 text-muted">
                        Tax (RM) :
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="card-subtitle mb-2 text-muted">0.00</h6>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-3">
                    <div className="col-4">
                      <h6 className="card-subtitle mb-2 text-muted fs-4">
                        Grand Total
                      </h6>
                    </div>
                    <div className="col-6">
                      <h6 className="card-subtitle mb-2 text-muted">
                        {price}.00
                      </h6>
                    </div>
                  </div>
                  <div className="card-footer bg-transparent">
                    {isOnclick ? (
                      <>
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <LongButton
                          name="submit"
                          className="rounded-pill"
                          onTap={validation}
                        >
                          pay
                        </LongButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSides>
    </>
  );
};

export default PurchaseProduct;
