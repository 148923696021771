export const validateEmail = (txt) => {
  var regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(txt);
};

export const validateRequired = (attribute) => {
  if (attribute === "" || attribute.trim() === "" || attribute === null) {
    return false;
  }
  return attribute.trim();
};
export const validatePhonelength = (txt) => {
  if (txt.length >= 10 && txt.length <= 12) {
    return true;
  }
  return false;
};
