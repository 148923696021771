import React from "react";
const LongButton = (props) => {
  const { buttonType, className, isDisabled = false, name, onTap } = props;
  const getBgColor = () => {
    if (isDisabled) return "cursor-not-allowed bg-gray-400";

    switch (buttonType) {
      case "close":
        return "btn btn-danger";
      case "cancel":
        return "btn btn-warning";
      case "submit":
        return "btn btn-primary";
      default:
        return "btn btn-primary";
    }
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="d-grid gap-2 col-6">
      <button
        className={`${getBgColor()} ${className}`}
        name={name}
        onClick={isDisabled ? undefined : onTap}
      >
        {props.children}
      </button>
    </div>
  );
};

export default LongButton;
