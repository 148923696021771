import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Login from "./views/login";
import Register from "./views/register";
import Error from "./views/Error";
import Apikey from "./views/getapikey";
import Purchaseproduct from "./views/purchaseproduct";
import HomePage from "./views/homepage";
import SmsCallback from "./views/smscallbacklisting";
import Purchaserecord from "./views/purchaserecordlisting";
import Dashboard from "./views/dashboard";
import Profile from "./views/profile";
import Bluestack from "./views/bluestack";
const RouterView = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/bluestack" element={<Bluestack />} />
      <Route path="/register" element={<Register />} />
      <Route path="/developer-api-key" element={<Apikey />} />
      <Route path="/purchaseProduct" element={<Purchaseproduct />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/smslog" element={<SmsCallback />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/purchaserecord" element={<Purchaserecord />} />
      <Route path="*" element={<Error />} />
    </Routes>
  </Router>
);
export default RouterView;
