import { APP_DEBUG, APP_API_URL } from "./config";
import { alertFailAuth } from "../components/alert";

export const Ajax = (obj) => {
  const token = localStorage.getItem("accessToken");
  const method = obj.method ? obj.method : "POST";
  const header = token
    ? {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    : {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
  const body = JSON.stringify(obj.data);
  if (APP_DEBUG) {
    console.log(header);
    console.log(APP_API_URL + obj.url, body);
  }
  return Promise.race([
    new Promise((resolve, reject) =>
      fetch(APP_API_URL + obj.url, {
        method: method,
        headers: header,
        body: body,
        timeout: 2000,
      })
        .then((response) => resolve(response.json()))
        .catch((error) => {
          reject(error);
        })
    ),
    timeout(),
  ])
    .then((responseJson) => {
      if (APP_DEBUG) {
        console.log(responseJson);
      }

      if (responseJson.status == "200") {
        return obj.success(responseJson);
      } else if (responseJson.status == "ConnectionTimeout") {
        alertFailAuth(null, null, "error");
      } else {
        return obj.fail(responseJson);
      }
    })
    .catch((e) => {
      if (APP_DEBUG) {
        console.log("Eror:" + APP_API_URL + obj.url, e);
      }
    })
    .then(() => obj.complete());
};
export const timeout = (ms = 10000) => {
  return new Promise((resolve) =>
    setTimeout(() => resolve("ConnectionTimeout"), ms)
  );
};
