import React from "react";

const Error = () => {
  const logout = () => {
    localStorage.clear();
  };
  React.useEffect(() => {
    logout();
  }, []);
  return (
    <>
      {/* <div class="d-flex align-items-center"> */}
      <p class="fs-1 fw-bold text-center">404 Not Found</p>
      {/* </div> */}
    </>
  );
};

export default Error;
