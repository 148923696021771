import Swal from "sweetalert2";

export const alertRegisterSuccess = (result, title, icon) => {
  Swal.fire({
    title: result.message,
    icon: icon,
    confirmButtonText: "OK",
    confirmButtonColor: "#3085d6",
  }).then(function () {
    window.location = "/";
  });
};
export const alertFailLogin = (content, title, icon) => {
  Swal.fire({
    title: content.message ? content.message : "Connection Timeout",
    icon: icon,
    confirmButtonText: "OK",
    confirmButtonColor: "#3085d6",
  });
};
export const alertFailAuth = (content, title, icon) => {
  Swal.fire({
    title: "You Have Been Logout",
    icon: icon,
    confirmButtonText: "OK",
    confirmButtonColor: "#3085d6",
  }).then(function () {
    window.location = "/";
    localStorage.clear();
  });
};

export const alert = (content, title, icon) => {
  Swal.fire({
    title: content,
    icon: icon,
    confirmButtonText: "OK",
    confirmButtonColor: "#3085d6",
  });
};
