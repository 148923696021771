import { createSlice } from "@reduxjs/toolkit";

export const reducer = createSlice({
  name: "counter",
  initialState: {
    user: null,
    token: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser } = reducer.actions;

export default reducer.reducer;
