import React from "react";

const Invoice = (props) => {
  const {
    name,
    invoice_id,
    invoice_date,
    price,
    customer_name,
    customer_phone,
    customer_email,
  } = props;

  return (
    <div className="contianer">
      <div className="p-4">
        <div className="float-right">
          <div className="d-flex bd-highlight mb-3">
            <div className="me-auto p-2 bd-highlight">
              <h3 className="mb-0">EvaLink</h3>
              <div>V03-08-02 DESIGNER OFFICE, SUNWAY VELOCITY,</div>
              <div>55200 KUALA LUMPUR</div>
            </div>

            <div className="p-2 bd-highlight">
              <div>Invoice no: {invoice_id}</div>
              <div>Invoice date: {invoice_date}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-4">
        <div className="row mb-4">
          <div className="col-sm-6 ">
            <h5 className="mb-3">To:</h5>
            <h3 className="text-dark mb-1">{customer_name}</h3>

            <div>Email:{customer_email}</div>
            <div>Phone:{customer_phone}</div>
          </div>
        </div>
        <div className="table-responsive-sm">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="center">#</th>
                <th>Item</th>

                <th className="right">Price</th>
                <th className="center">Qty</th>
                <th className="right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">1</td>
                <td className="left strong">{name}</td>

                <td className="right">RM{price}</td>
                <td className="center">1</td>
                <td className="right">{price}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-7 col-sm-6"></div>
          <div className="col-lg-4 col-sm-5 ">
            <table className="table table-clear">
              <tbody>
                <tr>
                  <td className="left">
                    <strong className="text-dark">Subtotal</strong>
                  </td>
                  <td className="right">RM{price}</td>
                </tr>

                <tr>
                  <td className="left">
                    <strong className="text-dark">Total</strong>
                  </td>
                  <td className="right">
                    <strong className="text-dark">RM{price}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Invoice;
