import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Pie } from "react-chartjs-2";

export const Chart = ({ chartData }) => {
  return <Bar data={chartData} />;
};

export const PieChart = ({ chartData }) => {
  return <Pie data={chartData} />;
};
