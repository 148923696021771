import React from "react";
import Logo from "../assets/NavSidesBar/logowhitecolor.png";
const NavbarTop = () => {
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-5">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            className="d-block mx-lg-auto img-fluid"
            alt="Evalink"
            width="120"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" href="/">
                SMS
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link active" href="/bluestack">
                BlueStack
              </a>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" href="/register">
                Register
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link active" href="/login">
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarTop;
