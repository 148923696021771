import React from "react";
const TextInput = (props) => {
  const {
    className,
    errorMessage = "Something went wrong.",
    // hints = [],
    inputType = "text",
    isDisabled = false,
    isEdit = true,
    isError = false,
    isRequired = false,
    label,
    maxLength = 100,
    name,
    onBlur,
    onChange,
    placeholder = ">",
    value = "",
  } = props;
  const inputRef = React.useRef();

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="form-floating mb-3">
      <input
        type={inputType}
        className={`form-control  ${className}`}
        id={name}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name}
        disabled={isDisabled}
        onBlur={onBlur}
        onChange={onChange}
        readOnly={!isEdit}
        ref={(input) => (inputRef.current = input)}
        value={value ?? ""}
      />
      <label htmlFor="floatingInput">
        {label}
        {isRequired ? <span className={"ms-1"}>{"*"}</span> : null}
      </label>
      {isError ? (
        <>
          <p
            className={"px-2 text-danger"}
            htmlFor={"name"}
            onClick={() => inputRef.current.focus()}
          >
            <i className="bi bi-exclamation-circle me-2"></i>
            {errorMessage}
          </p>
        </>
      ) : null}
    </div>
  );
};

export default TextInput;
