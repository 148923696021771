import React from "react";
import NavbarSides from "../components/navsidesbar";
import { Chart, PieChart } from "../components/chart";
import { Ajax } from "../js/ajax";
import { UserData } from "./Data";
const Dashboard = () => {
  const [apiKey, setApiKey] = React.useState("");
  const [MonthlyCost, setMonthlyCost] = React.useState(0);
  const [balance, setBalance] = React.useState(0);
  const [smsSendOut, setSmsSendOut] = React.useState(0);
  const [barChartData, setBarChartData] = React.useState([]);
  const [pieChartData, setPieChartData] = React.useState([]);
  const barChart = {
    labels: barChartData.map((data) => data.month),
    datasets: [
      {
        label: "Cost:",
        data: barChartData.map((data) => data.amount),
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };
  const pieChart = {
    labels: pieChartData.map((data) => data.subject),
    datasets: [
      {
        label: "Total Used",
        data: pieChartData.map((data) => data.total_subject_used),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  const getdashbaord = () => {
    Ajax({
      url: `getdashbaord`,
      complete: () => {},
      success: (result) => {
        setApiKey(result.data.api_key);
        setMonthlyCost(result.data.totalcost);
        setBalance(result.data.balance);
        setSmsSendOut(result.data.monthlySMSSend);
        setBarChartData(result.data.eachmonthcost);
        setPieChartData(result.data.piechartData);
      },
      fail: (fail) => {},
    });
  };
  const copy = () => {
    navigator.clipboard.writeText(apiKey);
  };
  React.useEffect(() => {
    getdashbaord();
  }, []);
  return (
    <>
      <NavbarSides>
        <div className="contianer p-3">
          <div className="d-flex justify-content-start mb-4">
            <h6 className="mb-0 text-muted">Your Devloper Key is: {apiKey} </h6>
          </div>
          <div className="row mb-2 d-flex justify-content-center">
            <div className="col-md-4">
              <div
                className="row g-0 border rounded  flex-md-row mb-4 shadow  position-relative"
                style={{ minHeight: "10em" }}
              >
                <div className="col-4 mt-2">
                  <i
                    className="bi bi-send-check-fill"
                    style={{ fontSize: "4.5em" }}
                  />
                </div>
                <div className="col-8 p-4 d-flex flex-column ">
                  <h5 className="mb-0">Cururent Month SMS Send out:</h5>
                  <div className="mb-1 text-muted">{smsSendOut}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="row g-0 border rounded  flex-md-row mb-4 shadow  position-relative"
                style={{ minHeight: "10em" }}
              >
                <div className="col-4 mt-2">
                  <i
                    className="bi bi-cash-coin"
                    style={{ fontSize: "4.5em" }}
                  />
                </div>
                <div className="col-8 p-4 d-flex flex-column ">
                  <h5 className="mb-0">Cururent Month Cost :</h5>
                  <div className="mb-1 text-muted">{MonthlyCost}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="row g-0 border rounded  flex-md-row mb-4 shadow position-relative"
                style={{ minHeight: "10em" }}
              >
                <div className="col-4 mt-2">
                  <i className="bi bi-coin" style={{ fontSize: "4.5em" }} />
                </div>
                <div className="col-8 p-4 d-flex flex-column ">
                  <h5 className="mb-0">Balance Left:</h5>
                  <div className="mb-1 text-muted">{balance}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8">
              <div className="d-flex justify-content-start  p-2">
                <h6 className="text-muted">Cost Used per Month </h6>
              </div>
              <Chart chartData={barChart} />
            </div>
            <div className="col-4">
              <div className="d-flex justify-content-start  p-2">
                <h6 className="text-muted">Subject Used This Month: </h6>
              </div>

              <PieChart chartData={pieChart} />
            </div>
          </div>
        </div>
      </NavbarSides>
    </>
  );
};

export default Dashboard;
