import React from "react";
export const BodyFilter = (props) => {
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div className="container row row-cols-2 row-cols-lg-5 g-2  mb-3  border bg-white rounded d-flex align-items-center d-flex justify-content-center">
        {props.children}
      </div>
    </>
  );
};

export const BodyTitle = (props) => {
  const { className, title } = props;

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div
      className={`container d-flex justify-content-start border-bottom border-grey mb-4`}
    >
      <div className={"my-2"}>
        <h3 className={"fw-bolder"}>{title}</h3>
      </div>
      {props.children}
    </div>
  );
};

export const TableNoData = (props) => {
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <tr>
      <>
        <td colSpan={"100%"}>
          <div className=" d-flex justify-content-center">
            <label className="my-5">
              <p className="fs-1 text-muted"> No Result Found</p>
            </label>
          </div>
        </td>
      </>
    </tr>
  );
};

export const Textwrap = (props) => {
  const { module } = props;
  const textwrapColor = () => {
    switch (module) {
      case "Pending": {
        return "bg-warning text-dark";
      }
      case "Cancelled": {
        return "bg-danger";
      }
      case "Completed": {
        return "bg-success";
      }
      default:
        break;
    }
    return "";
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <span className={`badge rounded-pill ${textwrapColor()}`}>
      {props.children}
    </span>
  );
};
