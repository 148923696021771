import React from "react";
import { uppercaseFirstLetter } from "../js/lib";
const DropdownList = (props) => {
  const {
    className,
    errorMessage = "Something went wrong.",
    first,
    hints = [],
    isDisabled = false,
    isEdit = true,
    isError = false,
    isRequired = false,
    items,
    label,
    module,
  } = props;

  const getCustomFirstOrAll = () => (!!first ? first : "All");
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div className="form-floating mb-3">
        <select
          className="form-select "
          aria-label="Default select example"
          disabled={isDisabled}
          onChange={props.valueChanged}
        >
          {items.length > 0
            ? items.map((item, index) => {
                return (
                  <option value={item} key={index}>
                    {!!item
                      ? uppercaseFirstLetter(item)
                      : getCustomFirstOrAll()}
                  </option>
                );
              })
            : null}
        </select>
        <label htmlFor="floatingInput">
          {label}
          {isRequired ? <span className={"ms-1"}>{"*"}</span> : null}
        </label>
      </div>
    </>
  );
};

export default DropdownList;
