import React from "react";
import "../assets/NavSidesBar/navsidesbar.css";
import { Ajax } from "../js/ajax";
import Logo from "../assets/NavSidesBar/logowhitecolor.png";
import { alertFailAuth } from "./alert";
import { useNavigate } from "react-router-dom";
const NavbarTop = (props) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const ontap = () => {
    setIsActive(!isActive);
  };
  const getblanace = () => {
    Ajax({
      url: `getbalance`,

      complete: () => {},
      success: (result) => {
        setBalance(result.data.balance);
      },
      fail: (fail) => {
        console.log("logout" + fail.status);
        if (fail.status == "310") {
          alertFailAuth(null, null, "error");
        }
      },
    });
  };
  const istoggled = () => {
    isActive
      ? document.body.classList.add("sb-sidenav-toggled")
      : document.body.classList.remove("sb-sidenav-toggled");
  };
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  React.useEffect(() => {
    istoggled();
    getblanace();
    return () => {};
  }, [isActive]);

  return (
    <>
      <div className="d-flex  bg-light" id="wrapper">
        <div
          className="border-end"
          // id={isActive ? "sb-sidenav-toggled " : "sidebar-wrapper"}
          id="sidebar-wrapper"
        >
          <div className="p-3 border-bottom d-flex justify-content-center">
            <img
              src={Logo}
              className="d-block mx-lg-auto img-fluid"
              alt="Evalink"
              width="200"
            />
          </div>

          <ul className="list-group list-group-flush mt-3">
            <li className="list-group-item my-2">
              <a className="nav-link" href="/dashboard">
                <div className="row">
                  <div className="col-4 d-flex justify-content-end">
                    <i className="bi bi-speedometer2 text-white fs-6" />
                  </div>
                  <div className="col-8 d-flex justify-content-start">
                    <label className="fw-bold  text-white fs-6">
                      Dashboard
                    </label>
                  </div>
                </div>
              </a>
            </li>
            <li className="list-group-item  my-2">
              <a className="nav-link" href="/purchaserecord">
                <div className="row">
                  <div className="col-4 d-flex justify-content-end">
                    <i className="bi bi-list text-white fs-6" />
                  </div>
                  <div className="col-8 d-flex justify-content-start">
                    <label className="fw-bold fw-bold text-white fs-6">
                      Top Up History
                    </label>
                  </div>
                </div>
              </a>
            </li>
            <li className="list-group-item  my-2">
              <a className="nav-link" href="/purchaseProduct">
                <div className="row">
                  <div className="col-4 d-flex justify-content-end">
                    <i className="bbi bi-coin text-white fs-6" />
                  </div>
                  <div className="col-8 d-flex justify-content-start">
                    <label className=" fw-bold text-white fs-6">Top Up</label>
                  </div>
                </div>
              </a>
            </li>
            <li className="list-group-item  my-2">
              <a className="nav-link" href="/smslog">
                <div className="row">
                  <div className="col-4 d-flex justify-content-end">
                    <i className="bi bi-file-earmark-bar-graph text-white " />
                  </div>
                  <div className="col-8 d-flex justify-content-start">
                    <label className=" fw-bold text-white fs-6">Log</label>
                  </div>
                </div>
              </a>
            </li>

            <li className="list-group-item  my-2">
              <a className="nav-link" onClick={logout}>
                <div className="row">
                  <div className="col-4 d-flex justify-content-end">
                    <i className="bi bi-door-open text-white fs-6" />
                  </div>
                  <div className="col-8 d-flex justify-content-start">
                    <label className=" fw-bold text-white fs-6">Logout</label>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div></div>
        </div>

        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand navbar-white bg-white">
            <div className="container-fluid">
              <a className="navbar-brand btn btn-light" onClick={ontap}>
                Menu <i className="navbar-toggler-icon"></i>
              </a>

              <div
                className="collapse navbar-collapse d-flex justify-content-end"
                id="navbarNav"
                style={{ marginRight: "1.5rem" }}
              >
                <ul className="navbar-nav ">
                  <li className="nav-item  border-end border-start">
                    <a className="nav-link active " href="/purchaserecord">
                      <div className="col-12">Balance Left : {balance}</div>
                      <div
                        className="col-12 text-muted text-center"
                        style={{ fontSize: "0.5rem" }}
                      >
                        Top Up History
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <div className="btn-group ">
                      <a
                        className="nav-link dropdown-toggle fs-4"
                        data-bs-toggle="dropdown"
                        data-bs-display="static"
                        aria-expanded="false"
                      >
                        {localStorage.getItem("UserFirstName")}
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="nav-link active " href="/profile">
                            <button className="dropdown-item" type="button">
                              Profile
                            </button>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="container-fluid p-2">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default NavbarTop;
