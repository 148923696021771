import React from "react";
import { BodyTitle } from "../components/body";
import { Ajax } from "../js/ajax";
import NavbarSides from "../components/navsidesbar";

const Apikey = () => {
  //call when on click
  const [apiKey, setApiKey] = React.useState("");
  const getapikey = () => {
    Ajax({
      url: `getApiKey`,
      complete: () => {},
      success: (result) => {
        setApiKey(result.data);
      },
      fail: (fail) => {},
    });
  };
  const copy = () => {
    navigator.clipboard.writeText(apiKey);
  };

  React.useEffect(() => {
    getapikey();
  }, []);
  return (
    <>
      <NavbarSides>
        <BodyTitle className={""} title={"Api Key"}></BodyTitle>
        <div className="d-flex justify-content-center mt-4 ">
          <div className="card col-lg-4 col-12  col-sm-8">
            <div className="card-body">
              <div className="col-12">
                <div className="row g-3 align-items-center">
                  <div className="col-auto">
                    <label htmlFor="inputPassword6" className="col-form-label">
                      Api Key :
                    </label>
                  </div>
                  <div className="col-auto">
                    <input
                      type="text"
                      id="inputPassword6"
                      className="form-control"
                      value={apiKey}
                      readOnly
                      onClick={copy}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      id="passwordHelpInline"
                      className="form-text"
                      onClick={copy}
                    >
                      <i
                        className="bi bi-clipboard-fill"
                        style={{ fontSize: "1.5rem" }}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarSides>
    </>
  );
};

export default Apikey;
