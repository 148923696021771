import React from "react";
import "../assets/HomePage/homepage.css";
import HomePagePic from "../assets/HomePage/homepage2.png";
import Api from "../assets/HomePage/Api.png";
import NavbarTop from "../components/topnavbar";
const HomePage = () => {
  return (
    <>
      <div id="gradientslightright">
        <NavbarTop />
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-row-reverse align-items-center g-5 py-5">
            <div className="col-12 col-lg-6">
              <img
                src={HomePagePic}
                className="d-block mx-lg-auto img-fluid"
                alt="Home Page"
                width="700"
                height="500"
              />
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">
                SMS One Time Password
              </h1>
              <p className="lead">
                an online transaction verification service by providing random
                passwords to user through sms. For example, current banks use
                OTP to authenticate user transactions or a shopping cart can use
                OTP to verify user's authencity.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex-row-reverse align-items-center g-5 py-5">
        <div className="col-lg-6 align-items-start">
          <h1 className="display-5 fw-bold lh-1 mb-3 text-start">
            Example Call Api with PHP
          </h1>
          <p className="lead text-start ">
            Easy to understand API Simple integrate via HTTP protocol
          </p>
          <p className="lead text-start">Sample coding provided (PHP)</p>
          <p className="lead text-start">
            Integration to send One Time Pin / One Time Password / OTP
          </p>
        </div>
        <div className="col-12 col-lg-6">
          <img
            src={Api}
            className="d-block mx-lg-auto img-fluid"
            alt="Home Page"
            width="700"
            height="500"
          />
        </div>
      </div>

      <div id="gradientslight">
        <div className="container px-4 py-5 ">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            Why Choose Our SMS Service
          </h1>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-globe  text-muted flex-shrink-0"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">Support Multiple Countries</h4>
                <p>Our core strength is serving the Asia Pacific region.</p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-send-check text-muted flex-shrink-0"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">HIGH SPEED AND RELIABLE</h4>
                <p>
                  Movider gives you fast and reliable service by seeking for the
                  shortest path for sending SMS with the 1.9 seconds per SMS.
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-code-slash  text-muted flex-shrink-0"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0">SMS API</h4>
                <p>
                  Using our Restful API developers can integrate ESMS into any
                  project We support all languages (PHP, .NET, C#, Java, Python,
                  Node etc),
                </p>
              </div>
            </div>
            <div className="col d-flex align-items-start">
              <div>
                <i
                  className="bi bi-shield-shaded  text-muted flex-shrink-0"
                  style={{ fontSize: "4em" }}
                />
                <h4 className="fw-bold mb-0 ">Secure Access</h4>
                <p>
                  We have HTTPS connection available. Your customers'
                  information is secure with us
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 py-5 ">
          <h1 className="display-5 fw-bold lh-1 mb-3">Our Pricing</h1>
          <div className="d-flex justify-content-center">
            <div className="table-responsive w-50">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th>Price (RM) / SMS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="text-start">
                      Malaysia
                    </th>
                    <td>0.10</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Singapore
                    </th>
                    <td>0.14</td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <th scope="row" className="text-start">
                      Thailand
                    </th>
                    <td>0.10</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Indonesia
                    </th>
                    <td>0.94</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Myanmar
                    </th>
                    <td>0.51</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Cambodia
                    </th>
                    <td>0.28</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Australia
                    </th>

                    <td>0.10</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-start">
                      Philippines
                    </th>

                    <td>0.10</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer  py-5 bg-dark">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 ">
            <div className="col d-flex align-items-start">
              <div className="col-12">
                <h5 className=" fw-bold text-muted">EvaLink</h5>
                <span className=" fw-bold text-muted">
                  V03-08-02 DESIGNER OFFICE, SUNWAY VELOCITY, 55200 KUALA LUMPUR
                </span>
              </div>
            </div>
            <div className="col d-flex align-items-start"></div>
            <div className="col d-flex align-items-start">
              <div>
                <h6 className="fw-bold text-muted">Contact Us:</h6>
                <h6 className="fw-bold text-muted">Phone: +603-9779 0801</h6>
                <h6 className="fw-bold text-muted">
                  Email:support@evalink.com
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
