import React from "react";
import TextInput from "../components/textInput.js";
import LongButton from "../components/longbutton.js";
import NavbarSides from "../components/navsidesbar";
import { alertRegisterSuccess } from "../components/alert";
import { validateRequired, validatePhonelength } from "../js/validation";
import { Ajax } from "../js/ajax";
import { BodyTitle } from "../components/body";
const Register = () => {
  // delcare varibale
  const [isEdit, setIsEdit] = React.useState(true);
  const [phone, setPhone] = React.useState("");
  const [ErrorPhone, setErrorPhone] = React.useState("");
  //
  const [email, setEmail] = React.useState("");

  const [oldpassword, setOldPassword] = React.useState("");
  const [errorOldPassword, setErrorOldPassword] = React.useState("");
  // password
  const [password, setPassword] = React.useState("");
  const [errorPassword, setErrorPassword] = React.useState("");
  const [reenterPassword, setReenterPassword] = React.useState("");
  const [errorReenterPassword, seterrorReenterPassword] = React.useState("");
  // name
  const [firstName, setFirstName] = React.useState("");
  const [errorFirstName, seterrorFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [errorLastName, seterrorLastName] = React.useState("");
  // set value for email input
  const phoneHandler = (e) => setPhone(e.target.value);
  const oldpasswordHandle = (e) => setOldPassword(e.target.value);
  const passwordHandler = (e) => setPassword(e.target.value);
  const firstNameHandler = (e) => setFirstName(e.target.value);
  const lastNameHandler = (e) => setLastName(e.target.value);
  const reenterPasswordHandler = (e) => setReenterPassword(e.target.value);
  // use for validation
  const [isErrorOldPassword, setIsErrorOldPassword] = React.useState("");
  const [isErrorPassowrd, setIsErrorPassowrd] = React.useState("");
  const [isErrorLastName, setIsErrorLastName] = React.useState("");
  const [isErrorFirstName, setIsErrorFirstName] = React.useState("");
  const [isErrorPhone, setIsErrorPhone] = React.useState("");
  const [isErrorReenterPassword, setIsErrorReenterPassword] =
    React.useState("");
  const [tempvalue, settempvalue] = React.useState(0);

  const [isOnclick, setIsOnclick] = React.useState(false);
  const updateProfileHandler = () => {
    setIsOnclick(true);
    const data = {
      password: password,
      lastname: lastName,
      firstname: firstName,
      phone: phone,
      oldpassword: oldpassword,
    };

    Ajax({
      url: `updateprofile`,
      data: data,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        setIsEdit(true);
      },
      fail: (fail) => {
        if (fail.status === "320") {
          if (fail.errors.password) {
            setErrorPassword(fail.errors.password);
            setIsErrorPassowrd(true);
          } else {
            setIsErrorPassowrd("");
          }
          if (fail.errors.lastname) {
            seterrorLastName(fail.errors.lastname);
            setIsErrorLastName(true);
          } else {
            setIsErrorLastName("");
          }
          if (fail.errors.firstname) {
            seterrorFirstName(fail.errors.firstname);
            setIsErrorFirstName(true);
          } else {
            setIsErrorFirstName("");
          }
          if (fail.errors.phone) {
            setErrorPhone(fail.errors.phone);
            setIsErrorPhone(true);
          } else {
            setIsErrorPhone("");
          }
        }
        if (fail.status === 300) {
          if (fail.message == "Wrong Password") {
            setIsErrorOldPassword(true);
            setErrorOldPassword("Old Password is Wrong");
          } else {
            setIsErrorOldPassword(false);
          }
        }
      },
    });
  };

  const isEditable = () => {
    setIsEdit(false);
    setPassword("");
    setOldPassword("");
    setReenterPassword("");
  };
  const getprofileHandler = () => {
    Ajax({
      url: `getprofile`,
      complete: () => {
        setIsOnclick(false);
      },
      success: (result) => {
        setPhone(result.data.phone.toString());
        setEmail(result.data.email);
        setPassword("*****");
        setOldPassword("*****");
        setFirstName(result.data.firstname);
        setLastName(result.data.lastname);
        setReenterPassword("*****");
      },
      fail: (fail) => {},
    });
  };
  //call when on click
  const validation = () => {
    if (validateRequired(phone) === false) {
      setErrorPhone("Phone Should Not be Empty");
      setIsErrorPhone(true);
    } else if (validatePhonelength(phone) === false) {
      setErrorPhone("Phone Must In between 10 to 12 digit");
      setIsErrorPhone(true);
    } else {
      setIsErrorPhone(false);
    }
    if (validateRequired(oldpassword) === false) {
      setIsErrorOldPassword(true);
      setErrorOldPassword("Old Password Should Not be Empty");
    } else {
      setIsErrorOldPassword(false);
    }

    // last name validation
    if (validateRequired(lastName) === false) {
      setIsErrorLastName(true);
      seterrorLastName("Last Name Should Not be Empty");
    } else {
      setIsErrorLastName(false);
    }
    // first name validation
    if (validateRequired(firstName) === false) {
      setIsErrorFirstName(true);
      seterrorFirstName("First Name Should Not be Empty");
    } else {
      setIsErrorFirstName(false);
    }
    //reentry password

    settempvalue(tempvalue + 1);
  };

  React.useEffect(() => {
    if (
      isErrorOldPassword === false &&
      isErrorFirstName === false &&
      isErrorLastName === false &&
      isErrorPhone === false
    ) {
      updateProfileHandler();
    }
  }, [tempvalue]);
  React.useEffect(() => {
    getprofileHandler();
  }, []);
  return (
    <>
      <NavbarSides>
        <BodyTitle className={""} title={"Profile"}></BodyTitle>
        <div className="d-flex justify-content-center mt-4 ">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <TextInput
                  classNameName={""}
                  errorMessage={errorFirstName}
                  hints={[]}
                  inputType={"text"}
                  isEdit={true}
                  isError={isErrorFirstName}
                  isRequired={true}
                  label={"First Name"}
                  maxLength={250}
                  name={"firstName"}
                  onChange={firstNameHandler}
                  placeholder="First Name"
                  value={firstName}
                  isDisabled={isEdit}
                />
              </div>
              <div className="col-6">
                <TextInput
                  classNameName={""}
                  errorMessage={errorLastName}
                  hints={[]}
                  inputType={"text"}
                  isEdit={true}
                  isError={isErrorLastName}
                  isRequired={true}
                  label={"Last Name"}
                  maxLength={250}
                  name={"lastName"}
                  onChange={lastNameHandler}
                  placeholder="Last Name"
                  value={lastName}
                  isDisabled={isEdit}
                />
              </div>
            </div>
            <div className="col-12">
              <TextInput
                classNameName={""}
                hints={[]}
                inputType={"text"}
                isEdit={true}
                isRequired={true}
                label={"Email"}
                maxLength={250}
                name={"email"}
                placeholder="Email"
                value={email}
                isDisabled={true}
              />
            </div>

            <div className="col-12">
              <TextInput
                classNameName={""}
                errorMessage={ErrorPhone}
                hints={[]}
                inputType={"text"}
                isEdit={true}
                isError={isErrorPhone}
                isRequired={true}
                label={"Phone"}
                maxLength={250}
                name={"Phone"}
                onChange={phoneHandler}
                placeholder="Phone"
                value={phone}
                isDisabled={isEdit}
              />
            </div>
            <div className="col-12">
              <TextInput
                classNameName={""}
                errorMessage={errorOldPassword}
                hints={[]}
                inputType={"password"}
                isEdit={true}
                isError={isErrorOldPassword}
                isRequired={true}
                label={"Old Password"}
                maxLength={250}
                name={"password"}
                onChange={oldpasswordHandle}
                placeholder="Email"
                value={oldpassword}
                isDisabled={isEdit}
              />
            </div>
            {isEdit ? null : (
              <div className="row">
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorPassword}
                    hints={[]}
                    inputType={"password"}
                    isEdit={true}
                    isError={isErrorPassowrd}
                    isRequired={true}
                    label={"Password"}
                    maxLength={250}
                    name={"password"}
                    onChange={passwordHandler}
                    placeholder="Email"
                    value={password}
                    isDisabled={isEdit}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    classNameName={""}
                    errorMessage={errorReenterPassword}
                    hints={[]}
                    inputType={"password"}
                    isEdit={true}
                    isError={isErrorReenterPassword}
                    isRequired={true}
                    label={"Reenter Password"}
                    maxLength={250}
                    name={"reenterPassword"}
                    onChange={reenterPasswordHandler}
                    placeholder="Reenter Password"
                    value={reenterPassword}
                    isDisabled={isEdit}
                  />
                </div>
              </div>
            )}

            <div className="d-flex justify-content-center">
              {isOnclick ? (
                <>
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : isEdit ? (
                <LongButton
                  onTap={isEditable}
                  name="submit"
                  className="rounded-pill"
                >
                  Edit
                </LongButton>
              ) : (
                <LongButton
                  onTap={validation}
                  name="submit"
                  className="rounded-pill"
                >
                  submit
                </LongButton>
              )}
            </div>
          </div>
        </div>
      </NavbarSides>
    </>
  );
};

export default Register;
